import Api from './Api'
import Utils from '../utilities/Utils'

export enum ReportType {
	REVENUE = 'revenue',
	EXPENSES = 'expenses'
}
export default class ReportsController extends Api {
	static get(type: ReportType, params: any = {}) {
		const ranges = params.ranges
		delete params.ranges;

		return fetch(`/api/reports/${type}?${Utils.encodeQueryData(params)}&${ranges.map(range => `ranges[]=${range}`).join('&')}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}
}