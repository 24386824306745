import Api from './Api';

export default class OnboardingController extends Api {
	static resendConfirmationEmail () {
		return fetch(`/api/onboarding/send-email-confirmation`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}

	static getForm() {
		return fetch(`/api/onboarding/form`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}

	static finish() {
		return fetch(`/api/onboarding/finish`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}
}