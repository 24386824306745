import Api from './Api'

export default class DirectionsController extends Api {
  static getForm() {
    return fetch(
      `/api/directions/form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }
    ).then((res) => res.json());
  }
}
