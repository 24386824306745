import { LedgerCondition } from "../types";
import Utils from "../utilities/Utils";
import Api from "./Api";

export default class jLedgerConditionsController extends Api {
	static get(params) {
		return fetch(`/api/invoices/conditions?${Utils.encodeQueryData(params)}`, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization": this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(condition: LedgerCondition) {
		return fetch(`/api/invoices/conditions`, {
			method: 'POST',
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization": this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ ledger_condition: condition })
		})
			.then(res => res.json())
	}

	static update(condition: LedgerCondition) {
		return fetch(`/api/invoices/conditions/${condition.id}`, {
			method: 'PUT',
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization": this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ ledger_condition: condition })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/invoices/conditions/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization": this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static form(id?: string) {
		return fetch(`/api/invoices/conditions/form?${Utils.encodeQueryData({ id: id })}`, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization": this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}
}