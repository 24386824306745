import { BillableRounding, FinancialYear, Settings } from "../types";
import Api from "./Api";

export interface ISettingsForm {
  countries: { label: string; value: string }[];
  currencies: { label: string; value: string }[];
  currency_formats: string[];
  date_formats: string[];
  invoice_locales: string[];
  ledger_item_variables: {
    amount: string;
    business_name: string;
    due_date: string;
    estimated_net_on: string;
    identifier: string;
    issue_date: string;
    link: string;
    number: string;
  };
  contract_variables: { [key: string]: string };
  financial_years: FinancialYear[]
  start_of_week: string[];
  time_formats: string[];
  time_zones: { label: string; value: string }[];
  billable_roundings: BillableRounding[];
}

export default class SettingsController extends Api {
  static get(): Promise<any> {
    return fetch(`/api/workspace/settings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static getForm(): Promise<ISettingsForm> {
    return fetch(`/api/workspace/settings/form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static update(settings: Settings) {
    return fetch("/api/workspace/settings", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ setting: settings }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static resetMenu() {
    return fetch("/api/workspace/settings/reset_menu", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.token
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);

  }
}
