import Api from './Api'

export default class SearchController extends Api {
	static search(searchValue: string = '') {
		return fetch(`/api/search?query=${searchValue}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}
}