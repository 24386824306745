import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  InvoiceMetadata,
  LedgerItem,
  LedgerItemPayment,
  PaymentForm,
  SendLedgerItemEmailParams,
} from "../types";

export default class InvoicesController extends Api {
  static getPayments(ledgerItemId: string, page: number = 1, parameters = {}) {
    return fetch(
      `/api/invoices/${ledgerItemId}/payments?page=${page}&${Utils.encodeQueryData(
        parameters
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static getForm(ledgerItemId: string, params: any) {
    return fetch(
      `/api/invoices/${ledgerItemId}/payments/form?${Utils.encodeQueryData(
        params
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(ledgerItemId: string, ledgerItemPayment: LedgerItemPayment) {
    return fetch(`api/invoices/${ledgerItemId}/payments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ ledger_item_payment: ledgerItemPayment }),
    }).then((res) => res.json());
  }

  static update(ledgerItemId: string, ledgerItemPayment: LedgerItemPayment) {
    return fetch(
      `api/invoices/${ledgerItemId}/payments/${ledgerItemPayment.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
        body: JSON.stringify({ ledger_item_payment: ledgerItemPayment }),
      }
    ).then((res) => res.json());
  }

  static delete(ledgerItemId: string, id: string) {
    return fetch(`/api/invoices/${ledgerItemId}/payments/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
