import { SMTP } from "../types";
import Api from "./Api";

export default class SmtpController extends Api {
  static get(): Promise<SMTP> {
    return fetch(`/api/workspace/smtp`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static getForm(): Promise<SMTP> {
    return fetch(`/api/workspace/smtp/form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static create(smtp: SMTP) {
    return fetch("/api/workspace/smtp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ smtp: smtp }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static update(smtp: SMTP) {
    return fetch("/api/workspace/smtp", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ smtp: smtp }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static delete() {
    return fetch(`/api/workspace/smtp`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static validate(smtp: SMTP): Promise<{ success: boolean | null }> {
    return fetch(`/api/workspace/smtp/validate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ smtp: smtp }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }
}
