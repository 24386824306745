import Api from "./Api";
import Utils from "../utilities/Utils";
import { PeppolSearchParams, PeppolSearchResult } from "../types";

export default class PeppolController extends Api {
  static search(params: PeppolSearchParams): Promise<PeppolSearchResult> {
    return fetch(`/api/peppol/search?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
