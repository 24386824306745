import Api from './Api';
import Utils from '../utilities/Utils';
import { PaymentInitiation, PaymentInitiationForm } from '../types';

export default class PaymentInitiationsController extends Api {
	static getPayment(id: string) {
		return fetch(`/api/payment_initiations/${id}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getPaymentInitiations(params: object) {
		return fetch(`/api/payment_initiations?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(payment_initiation: PaymentInitiation, paymentIds: string[]) {
		return fetch('/api/payment_initiations', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ payment_initiation: payment_initiation, payment_ids: paymentIds })
		})
			.then(res => res.json())
	}

	static update(payment_initiation: PaymentInitiation) {
		return fetch(`/api/payment_initiations/${payment_initiation.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ payment_initiation: payment_initiation })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/payment_initiations/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static getForm(params: { id?: string }): Promise<PaymentInitiationForm> {
		return fetch(`/api/payment_initiations/form?${Utils.encodeQueryData(params)}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static activate() {
		return fetch('/api/payment_initiations/activate', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}
}