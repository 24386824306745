import Api from './Api';

export default class SummaryController extends Api {
	static getInvoicesSummary() {
		return fetch(`/api/invoices/summary`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
		.then(res => res.json())
	}
}