import Api from "./Api";

export default class AccountantController extends Api {
  static getAccountants() {
    return fetch("/api/team/accountant", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(email: string) {
    return fetch("/api/team/accountants", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ accountant: { email: email } }),
    }).then((res) => res.json());
  }

  static resendCredentials(id: string) {
    return fetch(`/api/team/accountants/${id}/resend_credentials`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/team/accountants/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
