import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  CalendarEvent,
  Project,
  ProjectStatus,
  ProjectSummary,
} from "../types";

export default class ProjectsController extends Api {
  static getProject(id: string) {
    return fetch(`/api/projects/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getProjects(params: object) {
    return fetch(`/api/projects?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static payload() {
    return fetch(`/api/projects/payload`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(project: Project) {
    return fetch("/api/projects", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ project: project }),
    }).then((res) => res.json());
  }

  static update(project: Project) {
    return fetch(`/api/projects/${project.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ project: project }),
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/projects/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static projectView(id: string) {
    return fetch(`/api/projects/${id}/view`, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/projects/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTimeline(
    mode: "monthly"
  ): Promise<{ dates: string[]; projects: Project[]; leave: CalendarEvent[] }> {
    return fetch(`/api/projects/timeline?mode=${mode}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static statusUpdateAll(projectIds: string[], status: ProjectStatus) {
    return fetch(`/api/projects/bulk_status_update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ project_ids: projectIds, status: status }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(projectIds: string[]) {
    return fetch(`/api/projects/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ project_ids: projectIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
