import { Integration, IntegrationForm, IntegrationType, MarketplaceIntegration } from '../types'
import Api from './Api'
export default class IntegrationsController extends Api {
	static getIntegrations(): Promise<MarketplaceIntegration[]> {
		return fetch(`/api/integrations`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getForm(type: IntegrationType): Promise<IntegrationForm> {
		return fetch(`/api/integrations/${type}/form`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static create(integration) {
		return fetch(`/api/integrations`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ integration: integration })
		}).then(res => res.json())
	}

	static update(integration: Integration) {
		return fetch(`/api/integrations/${integration.type}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ integration: integration })
		}).then(res => res.json())
	}


	static destroy(type: IntegrationType) {
		return fetch(`/api/integrations/${type}`, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token,
			},
			credentials: 'same-origin',
			body: JSON.stringify({ type: type })
		})
			.then(res => res.json())
	}
}