import Api from "./Api";
import Utils from "../utilities/Utils";
import { DealStage } from "../types";

export default class DealStagesController extends Api {
  static getDealStage(id: string) {
    return fetch(`/api/deal_stages/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getDealStages(params: object) {
    return fetch(`/api/deal_stages?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(deal_stage: DealStage) {
    return fetch("/api/deal_stages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ deal_stage: deal_stage }),
    }).then((res) => res.json());
  }

  static update(deal_stage: DealStage) {
    return fetch(`/api/deal_stages/${deal_stage.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ deal_stage: deal_stage }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/deal_stages/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/deal_stages/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSettingsForm(params: { id?: string }) {
    return fetch(
      `/api/deal_stages/settings_form?${Utils.encodeQueryData(params)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
