import Api from "./Api";
import Utils from "../utilities/Utils";
import { Transaction } from "../types";

export default class TransactionsController extends Api {
  static getTransaction(id: string) {
    return fetch(`/api/transactions/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTransactions(params: object) {
    return fetch(`/api/transactions?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static update(transaction: Transaction) {
    return fetch(`/api/transactions/${transaction.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ transaction: transaction }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/transactions/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
