import Api from './Api'

export default class DashboardController extends Api {
	static getDashboard() {
		return fetch(`/api/dashboard`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token,
			},
			credentials: 'same-origin',
		})
    .then(res => res.json())
	}
}