import { Api } from ".";
import { ContentBlock, ContentBlockBase, ContentBlockResource } from "../types";

export default class ResourceContentBlocksController extends Api {
	static create(resource: ContentBlockResource, resourceId: string, block: ContentBlockBase) {
		return fetch(`/api/content_blocks`, {
			method: "POST",
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ resource: resource, resource_id: resourceId, content_block: block })
		}).then((res) => res.json());
	}

	static update(resource: ContentBlockResource, resourceId: string, block: ContentBlock) {
		return fetch(`/api/content_blocks/${block.id}`, {
			method: "PUT",
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ resource: resource, resource_id: resourceId, content_block: block })
		}).then((res) => res.json());
	}

	static delete(resource: ContentBlockResource, resourceId: string, id: string) {
		return fetch(`/api/content_blocks/${id}`, {
			method: "DELETE",
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ resource: resource, resource_id: resourceId })
		}).then((res) => res.json());
	}

	static duplicate(resource: ContentBlockResource, resourceId: string, blockId: string, position: number) {
		return fetch(`/api/content_blocks/${blockId}/duplicate`, {
			method: "POST",
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ resource: resource, resource_id: resourceId, content_block: { position: position } })
		}).then((res) => res.json());
	}
}
