import Api from "./Api";
import Utils from "../utilities/Utils";
import { BoardList, BoardListMetadata } from "../types";

export default class BoardListsController extends Api {
  static getBoardLists(boardId: string, params: object) {
    return fetch(
      `/api/boards/${boardId}/lists?${Utils.encodeQueryData(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static get(boardId: string, params: any) {
    return fetch(
      `/api/boards/${boardId}/lists/${params.id}?${Utils.encodeQueryData(
        params
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static create(boardId: string, boardList: BoardList) {
    return fetch(`/api/boards/${boardId}/lists`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ board_list: boardList }),
    }).then((res) => res.json());
  }

  static update(boardId: string, boardList: BoardList) {
    return fetch(`/api/boards/${boardId}/lists/${boardList.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ board_list: boardList }),
    }).then((res) => res.json());
  }

  static delete(boardId: string, id: string) {
    return fetch(`/api/boards/${boardId}/lists/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static metadata(boardId: string, id: string): Promise<BoardListMetadata> {
    return fetch(`/api/boards/${boardId}/lists/${id}/metadata`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
