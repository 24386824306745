import Api from "./Api";
import Utils from "../utilities/Utils";
import { BizzeyFile, BlobResponse } from "../types";

interface GetFilesParams {
  page: number;
  parent_file_id?: string;
  resource_type?: string;
  resource_id?: string;
  [key: string]: any;
}

export default class FilesController extends Api {
  static getFiles(params: GetFilesParams) {
    return fetch(`/api/files?${Utils.encodeQueryData(params, true)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/files/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getView(params: object = {}) {
    return fetch(`/api/files/view?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTreeView(params: object = {}) {
    return fetch(`/api/files/tree_view?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(file: BizzeyFile) {
    var formData = new FormData();

    for (var key in file) {
      if (!["id"].includes(key)) formData.append(`file[${key}]`, file[key]);
    }

    return fetch(`/api/files`, {
      method: "POST",
      headers: {
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: formData,
    }).then((res) => res.json());
  }

  static update(file: BizzeyFile) {
    var formData = new FormData();

    for (var key in file) {
      if (!["id"].includes(key)) formData.append(`file[${key}]`, file[key]);
    }

    return fetch(`/api/files/${file.id}`, {
      method: "PUT",
      headers: {
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: formData,
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/files/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static download(id: string): Promise<BlobResponse> {
    return fetch(`/api/files/${id}/download`, {
      method: "GET",
      headers: {
        Authorization: this.token,
      },
    }).then((res) => {
      return new Promise((resolve, reject) => {
        res.blob().then((blob) => {
          resolve({
            filename: res.headers
              .get("content-disposition")
              .match(/filename="(.+)"/)[1],
            blob: blob,
          });
        });
      });
    });
  }

  static deleteAll(fileIds: string[]) {
    return fetch(`/api/files/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ file_ids: fileIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
