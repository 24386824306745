import Api from './Api';
import Utils from '../utilities/Utils';
import { WorkType } from '../types';

export default class WorkTypesController extends Api {
	static getWorkType(id: string) {
		return fetch(`/api/work_types/${id}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getWorkTypes(params: object) {
		return fetch(`/api/work_types?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(work_type: WorkType) {
		return fetch('/api/work_types', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ work_type: work_type })
		})
			.then(res => res.json())
	}

	static update(work_type: WorkType) {
		return fetch(`/api/work_types/${work_type.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ work_type: work_type })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/work_types/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static getForm(params: { id?: string }) {
		return fetch(`/api/work_types/form?${Utils.encodeQueryData(params)}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}
}