import { Api } from '.';
import Utils from '../utilities/Utils';

export default class SubscriptionController extends Api {
  static fetchForm() {
    return fetch('/api/workspace/subscription/form', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.token
      },
    })
      .then(res => res.json())
      .catch(error => console.error(error))
  }

  static updatePlan(planId: string) {
    return fetch("/api/workspace/subscription", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": this.token
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        plan_id: planId
      })
    })
      .then(res => res.json())
  }

  static deleteSubscription() {
    return fetch('/api/workspace/subscription', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.token
      },
    })
      .then(res => res.json())
  }

  static getInvoices(params: object): Promise<{
    bizzey_invoices: any[]
    current_page: number
    per_page: number
    total_entries: number
    total_pages: number
  }> {
    return fetch(`/api/workspace/subscription/invoices?${Utils.encodeQueryData(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.token
      },
    })
      .then(res => res.json())
      .catch(error => console.error(error))
  }

  static downloadInvoice(id: string) {
    return fetch(`/api/workspace/subscription/invoice_download?${Utils.encodeQueryData({ id: id })}`, {
      method: 'GET',
      headers: {
        'Authorization': this.token
      },
    })
      .then(res => {
        return new Promise((resolve, reject) => {
          res.blob().then(blob => {
            resolve({
              filename: res.headers.get('content-disposition').match(/filename="(.+)"/)[1],
              blob: blob
            })
          })
        })
      })
  }
}