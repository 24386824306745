import { WorkspaceTax } from '../types'
import Utils from '../utilities/Utils'
import Api from './Api'

export default class TaxesController extends Api {
	static getTaxes(params: object) {
		return fetch(`/api/taxes?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(tax: WorkspaceTax) {
		return fetch('/api/taxes', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ tax: tax })
		})
			.then(res => res.json())
	}

	static update(tax: WorkspaceTax) {
		return fetch(`/api/taxes/${tax.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ tax: tax })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/taxes/${id}`, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static getForm(params: { id?: string }) {
		return fetch(`/api/taxes/form?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token,
			},
			credentials: 'same-origin'
		}).then(res => res.json())
	}
}