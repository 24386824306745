import Api from "./Api";
import Utils from "../utilities/Utils";

export default class AccountsController extends Api {
  static getAccount(id: string) {
    return fetch(`/api/accounts/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getAccounts(params: object) {
    return fetch(`/api/accounts?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static delete(id: string): Promise<any> {
    return fetch(`/api/accounts/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(accountIds: string[]) {
    return fetch(`/api/accounts/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ account_ids: accountIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static synchronize(id: string): Promise<{ success?: string }> {
    return fetch(`/api/accounts/${id}/synchronize`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static reauthorize(id: string): Promise<{ redirect_uri?: string }> {
    return fetch(`/api/accounts/${id}/reauthorize`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
