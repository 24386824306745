import { VATResponse } from "../types";
import Api from "./Api";

export default class VATController extends Api {
  static getDetails(vatNumber: string): Promise<VATResponse> {
    return fetch(
      `/api/vat/${encodeURI(vatNumber.replace(/\s+/g, "").replace(/\./g, ""))}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      }
    ).then((res) => res.json());
  }
}
