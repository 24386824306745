import Api from "./Api";
import Utils from "../utilities/Utils";
import {
  BizzeyFile,
  Task,
  TaskTimeTrackingReportData,
  TaskTimeTrackingViewReportType,
} from "../types";

export default class TasksController extends Api {
  static getTask(id: string) {
    return fetch(`/api/tasks/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTasks(params: object) {
    return fetch(`/api/tasks?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(task: Task) {
    return fetch("/api/tasks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ task: task }),
    }).then((res) => res.json());
  }

  static update(task: Task) {
    return fetch(`/api/tasks/${task.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ task: task }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/tasks/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: object) {
    return fetch(`/api/tasks/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static createAttachment(taskId: string, attachment: BizzeyFile) {
    var formData = new FormData();

    for (var key in attachment) {
      if (!["id"].includes(key))
        formData.append(`attachment[${key}]`, attachment[key]);
    }

    return fetch(`/api/tasks/${taskId}/attachments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: formData,
    }).then((res) => res.json());
  }

  static updateAttachment(taskId: string, attachment: BizzeyFile) {
    var formData = new FormData();

    for (var key in attachment) {
      if (!["id"].includes(key))
        formData.append(`attachment[${key}]`, attachment[key]);
    }

    return fetch(`/api/tasks/${taskId}/attachments/${attachment.id}`, {
      method: "PUT",
      headers: {
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: formData,
    }).then((res) => res.json());
  }

  static deleteAttachment(taskId: string, attachmentId: string) {
    return fetch(`/api/tasks/${taskId}/attachments/${attachmentId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static bulkMove(taskIds: string[], destinationListId: string) {
    return fetch(`/api/tasks/bulk_move`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ task_ids: taskIds, list_id: destinationListId }),
    }).then((res) => res.json());
  }

  static bulkDelete(taskIds: string[]) {
    return fetch(`/api/tasks/bulk_delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ task_ids: taskIds }),
    }).then((res) => res.json());
  }

  static getTimeEntriesReport(data: {
    taskId: string;
    type: TaskTimeTrackingViewReportType;
  }): Promise<TaskTimeTrackingReportData> {
    return fetch(
      `/api/tasks/${data.taskId}/timesheet?${Utils.encodeQueryData(data)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
