import Api from './Api';
import Utils from '../utilities/Utils';

export default class CalendarsController extends Api {
	static getCalendars(params: object) {
		return fetch(`/api/calendars?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/calendars/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}
}