import Api from './Api';
import Utils from '../utilities/Utils';
import { BlobResponse, FormResponse, SpreadsheetExportType } from '../types';

export default class FormResponsesController extends Api {
	static getFormResponse(formId: string, id: string) {
		return fetch(`/api/forms/${formId}/responses/${id}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getFormResponses(formId: string, params: object) {
		return fetch(`/api/forms/${formId}/responses?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static update(formResponse: FormResponse) {
		return fetch(`/api/forms/${formResponse.form_id}/responses/${formResponse.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ form_response: formResponse })
		})
			.then(res => res.json())
	}

	static delete(formResponse: FormResponse) {
		return fetch(`/api/forms/${formResponse.form_id}/responses/${formResponse.id}`, {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static export(formId: string, params: { spreadsheet_type: SpreadsheetExportType }): Promise<BlobResponse> {
		return fetch(`/api/forms/${formId}/responses/export?${Utils.encodeQueryData(params)}`, {
			method: 'GET',
			headers: {
				'Authorization': this.token
			},
		})
			.then(res => {
				return new Promise((resolve, reject) => {
					res.blob().then(blob => {
						resolve({
							filename: res.headers.get('content-disposition').match(/filename="(.+)"/)[1],
							blob: blob
						})
					})
				})
			})
	}
}