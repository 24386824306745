import { Device } from "../types";
import Utils from "../utilities/Utils";
import Api from "./Api";

export default class DeviceController extends Api {
  static syncDevice(device: Device) {
    return fetch("/api/user/devices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ device: device }),
    }).then((res) => res.json());
  }

  static call(phoneNumber: string) {
    return fetch(`/api/user/devices/call`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ phone_number: phoneNumber }),
    }).then((res) => res.json());
  }
}
