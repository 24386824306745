import Api from "./Api";
import Utils from "../utilities/Utils";
import { BlobResponse, Document, ExportDocumentParams } from "../types";

export default class DocumentsController extends Api {
  static getDocuments(params: object) {
    return fetch(`/api/documents?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(document: Document) {
    return fetch("/api/documents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ document: document }),
    }).then((res) => res.json());
  }

  static update(document: Document) {
    return fetch(`/api/documents/${document.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ document: document }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/documents/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/documents/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(documentIds: string[]) {
    return fetch(`/api/documents/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ document_ids: documentIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static exportDocuments(
    params: ExportDocumentParams
  ): Promise<BlobResponse | { success: true }> {
    return fetch(`/api/documents/export?${Utils.encodeQueryData(params)}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static sendFinancialDocuments(params) {
    return fetch(`/api/documents/send_financial_documents`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ ...params }),
    }).then((res) => res.json());
  }
}
