import { Blob, DirectUpload, DirectUploadDelegate } from "@rails/activestorage";
import {
  RAILS_DIRECT_UPLOAD_URL,
  RAILS_PUBLIC_DIRECT_UPLOAD_URL,
} from "../Constants";
import Api from "./Api";
import { ActiveStorageBlob } from "../types";

export default class ActiveStorageController extends Api {
  static upload(
    file: File,
    callback: (error: Error, blob: ActiveStorageBlob) => void,
    uploadDelegate?: DirectUploadDelegate
  ) {
    return new DirectUpload(
      file,
      RAILS_DIRECT_UPLOAD_URL,
      uploadDelegate
    ).create(callback);
  }

  static uploadPromise(
    file: File,
    uploadDelegate?: DirectUploadDelegate
  ): Promise<ActiveStorageBlob> {
    return new Promise((resolve, reject) => {
      this.upload(
        file,
        (error, blob: ActiveStorageBlob) => {
          if (error) reject(error);
          resolve(blob);
        },
        uploadDelegate
      );
    });
  }

  static uploadPublic(
    file: File,
    callback: (error: Error, blob: Blob) => void,
    uploadDelegate?: DirectUploadDelegate
  ) {
    return new DirectUpload(
      file,
      RAILS_PUBLIC_DIRECT_UPLOAD_URL,
      uploadDelegate
    ).create(callback);
  }

  static uploadPublicPromise(
    file: File,
    uploadDelegate?: DirectUploadDelegate
  ): Promise<ActiveStorageBlob> {
    return new Promise((resolve, reject) => {
      this.uploadPublic(
        file,
        (error, blob: ActiveStorageBlob) => {
          if (error) reject(error);
          resolve(blob);
        },
        uploadDelegate
      );
    });
  }

  static getBlobUrl(blob: Blob): Promise<{ url: string }> {
    return fetch(
      `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
    );
  }
}
