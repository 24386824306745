import Api from './Api';
import Utils from '../utilities/Utils'
import { ExpenseCategory } from '../types';

export default class ExpenseCategoriesController extends Api {
    static getCategories(params: object) {
        return fetch(`/api/expense_categories?${Utils.encodeQueryData(params)}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            credentials: 'same-origin',
        })
            .then(res => res.json())
    }

    static create(category: ExpenseCategory) {
        return fetch('/api/expense_categories', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            credentials: 'same-origin',
            body: JSON.stringify({ category: category })
        })
            .then(res => res.json())
    }

    static update(category: ExpenseCategory) {
        return fetch(`/api/expense_categories/${category.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            credentials: 'same-origin',
            body: JSON.stringify({ category: category })
        })
            .then(res => res.json())
    }

    static delete(id: string) {
        return fetch(`/api/expense_categories/${id}`, {
            'method': 'DELETE',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": this.token
            },
            credentials: 'same-origin',
        })
            .then(res => res.json())
    }

    static getForm(id?: string) {
        const params = { id: id }

        return fetch(`/api/expense_categories/form?${Utils.encodeQueryData(params)}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.token
            },
            credentials: 'same-origin',
        })
            .then(res => res.json())
    }
}