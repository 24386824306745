import { Api } from ".";
import { Me, Workspace } from "../types";
import Utils from "../utilities/Utils";

export default class UserController extends Api {
  static me() {
    return fetch("/api/user", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static updateUser(me: Me) {
    return fetch("/api/user", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ me: me }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error));
  }

  static deleteUser() {
    return fetch("/api/user", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error));
  }

  static getWorkspace(id: string) {
    return fetch(`/api/user/workspaces/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getWorkspaces(params: object) {
    return fetch(`/api/user/workspaces?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static createWorkspace() {
    return fetch("/api/user/workspaces", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static updateWorkspace(workspace: Workspace) {
    return fetch(`/api/user/workspaces/${workspace.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ workspace: workspace }),
    }).then((res) => res.json());
  }

  static getInviteWorkspace(inviteToken: string) {
    return fetch(
      `/api/user/workspaces/invite?${Utils.encodeQueryData({
        invite_token: inviteToken,
      })}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static acceptWorkspaceInvite(
    inviteToken: string
  ): Promise<{ success: boolean }> {
    return fetch(`/api/user/workspaces/accept_invite`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ invite_token: inviteToken }),
    }).then((res) => res.json());
  }

  static leaveWorkspace(userWorkspaceSettingId: string) {
    return fetch(`/api/user/workspaces/leave`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ id: userWorkspaceSettingId }),
    }).then((res) => res.json());
  }

  static deleteWorkspace(workspaceId: string) {
    return fetch(`/api/user/workspaces/delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ id: workspaceId }),
    }).then((res) => res.json());
  }

  static otpForm(): Promise<{
    otp_enabled: boolean;
    otp_secret?: string;
    qr_code?: string;
  }> {
    return fetch(`/api/user/otp_form`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static otpConfigure(otpSecret: string, code: string) {
    return fetch(`/api/user/otp_configure`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ otp_secret: otpSecret, code: code }),
    }).then((res) => res.json());
  }

  static otpDisable(password: string) {
    return fetch(`/api/user/otp_disable`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ password: password }),
    }).then((res) => res.json());
  }
}
