import Api from './Api';
import Utils from '../utilities/Utils';
import { Contact, Contract, SendContractEmailParams, SignatureResult } from '../types';

export default class ContractsController extends Api {
	static getContract(id: string) {
		return fetch(`/api/contracts/${id}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getContracts(params: object) {
		return fetch(`/api/contracts?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(contract: Contract) {
		return fetch('/api/contracts', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ contract: contract })
		})
			.then(res => res.json())
	}

	static update(contract: Contract) {
		return fetch(`/api/contracts/${contract.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ contract: contract })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/contracts/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static duplicate(contractId: string) {
		return fetch(`/api/contracts/${contractId}/duplicate`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getForm(params: { id?: string }) {
		return fetch(`/api/contracts/form?${Utils.encodeQueryData(params)}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static sign(contractId: string, signature: SignatureResult, signKey: string) {
		var formData = new FormData();

		formData.append('key', signKey);

		for (var key in signature) {
			if (!["id"].includes(key)) formData.append(`signature[${key}]`, signature[key]);
		}

		return fetch(`/api/contracts/${contractId}/sign`, {
			method: "POST",
			credentials: "same-origin",
			body: formData,
		}).then((res) => res.json());
	}

	static getEmailForm(emailFormParams: { id: string }) {
		return fetch(`/api/contracts/${emailFormParams.id}/email_form?${Utils.encodeQueryData(emailFormParams)}`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
		}).then((res) => res.json());
	}

	static send(contractId: string, email: SendContractEmailParams) {
		return fetch(`/api/contracts/${contractId}/send`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
			body: JSON.stringify({ email: email }),
		}).then((res) => res.json());
	}

	static getSigneeLinks(id: string): Promise<{ signee_links: { signee: Contact, url: string }[] }> {
		return fetch(`/api/contracts/${id}/signee_links`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}
}