import Api from "./Api";
import Utils from "../utilities/Utils";
import { EmailTemplate } from "../types";

export default class EmailTemplatesController extends Api {
  static getEmailTemplate(id: string) {
    return fetch(`/api/email_templates/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getEmailTemplates(params: object) {
    return fetch(`/api/email_templates?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(emailTemplate: EmailTemplate) {
    return fetch("/api/email_templates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ email_template: emailTemplate }),
    }).then((res) => res.json());
  }

  static update(emailTemplate: EmailTemplate) {
    return fetch(`/api/email_templates/${emailTemplate.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ email_template: emailTemplate }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/email_templates/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/email_templates/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
