import Api from './Api';
import Utils from '../utilities/Utils';
import { Form } from '../types';

export default class FormsController extends Api {
	static getForm(id: string) {
		return fetch(`/api/forms/${id}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getForms(params: object) {
		return fetch(`/api/forms?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(form: Form) {
		return fetch('/api/forms', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ form: form })
		})
			.then(res => res.json())
	}

	static update(form: Form) {
		return fetch(`/api/forms/${form.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ form: form })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/forms/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}

	static duplicate(formId: string) {
		return fetch(`/api/forms/${formId}/duplicate`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static getFormForm(params: { id?: string }) {
		return fetch(`/api/forms/form?${Utils.encodeQueryData(params)}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static submit(formId: string, data: { [key: string]: any }) {
		return fetch(`/api/forms/${formId}/submit`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ data: data })
		})
			.then(res => res.json())
	}
}