import Api from "./Api";
import Utils from "../utilities/Utils";
import { Deal, DealStage } from "../types";

export default class DealsController extends Api {
  static getDeal(id: string) {
    return fetch(`/api/deals/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getDeals(params: object) {
    return fetch(`/api/deals?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(deal: Deal) {
    return fetch("/api/deals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ deal: deal }),
    }).then((res) => res.json());
  }

  static update(deal: Deal) {
    return fetch(`/api/deals/${deal.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ deal: deal }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/deals/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(dealIds: string[]) {
    return fetch(`/api/deals/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ deal_ids: dealIds }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getForm(params: { id?: string }) {
    return fetch(`/api/deals/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getBoard() {
    return fetch(`/api/deals/board`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getView(id: string): Promise<{
    deal: Deal;
    deal_stages: DealStage[];
  }> {
    return fetch(`/api/deals/${id}/view`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static bulkMove(dealIds: string[], destinationListId: string) {
    return fetch(`/api/deals/bulk_move`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        deal_ids: dealIds,
        deal_stage_id: destinationListId,
      }),
    }).then((res) => res.json());
  }
}
