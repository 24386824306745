import Api from "./Api";
import Utils from "../utilities/Utils";
import { BoardLabel } from "../types";

export default class BoardLabelsController extends Api {
	static get(params: any) {
		return fetch(`/api/board_labels/${params.id}?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
		}).then((res) => res.json());
	}

	static create(boardLabel: BoardLabel) {
		return fetch(`/api/board_labels`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
			body: JSON.stringify({ board_label: boardLabel }),
		}).then((res) => res.json());
	}

	static update(boardLabel: BoardLabel) {
		return fetch(`/api/board_labels/${boardLabel.id}`, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
			body: JSON.stringify({ board_label: boardLabel }),
		}).then((res) => res.json());
	}

	static delete(id: string) {
		return fetch(`/api/board_labels/${id}`, {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: this.token,
			},
			credentials: "same-origin",
		}).then((res) => res.json());
	}
}
