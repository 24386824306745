import { PaymentMethodType, Workspace } from "../types";
import Api from "./Api";

export default class WorkspaceController extends Api {
  static update(workspace: Workspace) {
    return fetch("/api/workspace", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ workspace: workspace }),
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static requestPeppolAccess() {
    return fetch("/api/workspace/request_peppol_access", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => console.error(error))
      .then((response) => response);
  }

  static updateCard(id: string) {
    return fetch("/api/workspace/card", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ id }),
    }).then((res) => res.json());
  }

  static getCardForm(
    type: PaymentMethodType
  ): Promise<{ setup_intent_secret: string }> {
    return fetch(`/api/workspace/card/form?type=${type}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
